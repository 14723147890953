html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  background-color: #fff;
}

h2 {
  font-family: 'bebas-neue-pro', sans-serif;
  font-weight: 600;
  font-style: normal;
}
